import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import styles from './Reports.css';

Chart.register(...registerables);

function Reports() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    fetchOpsTempoData();
  }, []);

  const fetchOpsTempoData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/events', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data) {
        throw new Error('No data received');
      }

      const events = response.data;

      // Calculate the date range for the past 6 months and the next 6 months
      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setMonth(startDate.getMonth() - 6);
      const endDate = new Date(currentDate);
      endDate.setMonth(endDate.getMonth() + 6);

      const weeks = {};

      // Initialize weeks within the date range
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 7)) {
        const weekNumber = getWeekNumber(d);
        weeks[weekNumber] = { total: 0 };
      }

      // Process events data to create chartData grouped by weeks (Monday to Sunday)
      events.forEach((event) => {
        const eventDate = new Date(event.EventDate);
        const weekNumber = getWeekNumber(eventDate);

        if (!weeks[weekNumber]) {
          weeks[weekNumber] = { total: 0 };
        }

        weeks[weekNumber].total += event.Races || 1; // Assume event.Races exists and defaults to 1 if not specified
      });

      const labels = Object.keys(weeks).sort();
      const totalRacesData = labels.map((week) => weeks[week].total);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Total Races per Week',
            data: totalRacesData,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: false,
            tension: 0.1,
          },
        ],
      });
    } catch (error) {
      console.error('Failed to fetch ops tempo data:', error);
      alert('Failed to fetch ops tempo data. Please try again.');
    }
  };

  const getWeekNumber = (date) => {
    const firstJan = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - firstJan + (firstJan.getDay() === 0 ? 1 : 8 - firstJan.getDay()) * 24 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000));
    return Math.ceil(days / 7);
  };

  return (
    <div className={styles['reports-container']}>
      <h1>Reports</h1>
      <p>Welcome to the Reports. Here you can get an overview of all activities.</p>
      <div className={styles['chart-container']}>
        <Line
          data={chartData}
          options={{
            responsive: true,
            scales: {
              x: { title: { display: true, text: 'Weeks' } },
              y: { title: { display: true, text: 'Number of Races' } },
            },
          }}
        />
      </div>
    </div>
  );
}

export default Reports;
