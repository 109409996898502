import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './MasterList.css';

const MasterList = () => {
  const [services, setServices] = useState([]);
  const [groupedServices, setGroupedServices] = useState({});
  const [expandedService, setExpandedService] = useState(null);
  const [newTask, setNewTask] = useState({});
  const [tasks, setTasks] = useState({});
  const [editedTask, setEditedTask] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/all-services', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = Array.isArray(response.data) ? response.data : [];
      setServices(data);
      groupServicesByCategory(data);
      data.forEach((service) => {
        if (service.ServiceID) {
          fetchTasksForService(service.ServiceID);
        } else {
          console.error('ServiceID is undefined for service:', service);
        }
      });
    } catch (error) {
      console.error('Failed to fetch services', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const fetchTasksForService = async (serviceId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/task-list/service/${serviceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTasks((prevTasks) => ({
        ...prevTasks,
        [serviceId]: response.data,
      }));
    } catch (error) {
      console.error(`Failed to fetch tasks for ServiceID ${serviceId}`, error);
    }
  };

  const groupServicesByCategory = (services) => {
    const grouped = services.reduce((acc, service) => {
      const category = service.ServiceCategory || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(service);
      return acc;
    }, {});
    setGroupedServices(grouped);
  };

  const handlePlusClick = (serviceId) => {
    setExpandedService(expandedService === serviceId ? null : serviceId);
  };

  const handleInputChange = (e, serviceId, taskId = null) => {
    const { name, value } = e.target;
    if (taskId) {
      setEditedTask({ ...editedTask, [taskId]: { ...editedTask[taskId], [name]: value } });
    } else {
      setNewTask({ ...newTask, [serviceId]: { ...newTask[serviceId], [name]: value } });
    }
  };

  const handleSubmit = async (e, serviceId) => {
    e.preventDefault();
    if (!newTask[serviceId]?.TaskName) {
      alert('Task Name is required');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(
        '/api/task-list',
        {
          TaskName: newTask[serviceId]?.TaskName,
          ServiceID: serviceId,
          StartDateTminus: newTask[serviceId]?.StartDateTminus,
          DueDateTminus: newTask[serviceId]?.DueDateTminus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert('Task added successfully!');
      setExpandedService(null);
      fetchTasksForService(serviceId);
    } catch (error) {
      console.error('Failed to add task', error);
      alert('Failed to add task');
    }
  };

  const handleEditSubmit = async (taskId, serviceId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/task-list/${taskId}`, editedTask[taskId], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Task updated successfully!');
      fetchTasksForService(serviceId);
    } catch (error) {
      console.error('Failed to update task', error);
      alert('Failed to update task');
    }
  };

  const handleDelete = async (taskId, serviceId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/task-list/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Task deleted successfully!');
      fetchTasksForService(serviceId);
    } catch (error) {
      console.error('Failed to delete task', error);
      alert('Failed to delete task');
    }
  };

  return (
    <div>
      <h1>Master List</h1>
      <button onClick={() => navigate('/templates', { state: { defaultTab: 'NoteTemplates' } })}>
        All Templates
      </button>
      {Object.keys(groupedServices).map((category) => (
        <div key={category}>
          <h2>{category}</h2>
          <table>
            <tbody>
              {groupedServices[category].map((service) => (
                <React.Fragment key={service.ServiceID}>
                  <tr>
                    <td style={{ display: 'flex', alignItems: 'center' }}>
                      <button onClick={() => handlePlusClick(service.ServiceID)}>+</button>
                      <span className="service-name">{service.ServiceName}</span>
                    </td>
                  </tr>
                  {expandedService === service.ServiceID && (
                    <tr>
                      <td colSpan="2">
                        <form onSubmit={(e) => handleSubmit(e, service.ServiceID)}>
                          <div>
                            <label>Task Name:</label>
                            <input
                              type="text"
                              name="TaskName"
                              value={newTask[service.ServiceID]?.TaskName || ''}
                              onChange={(e) => handleInputChange(e, service.ServiceID)}
                              required
                            />
                          </div>
                          <div>
                            <label>Start Date T-:</label>
                            <input
                              type="number"
                              name="StartDateTminus"
                              value={newTask[service.ServiceID]?.StartDateTminus || ''}
                              onChange={(e) => handleInputChange(e, service.ServiceID)}
                              required
                            />
                          </div>
                          <div>
                            <label>Due Date T-:</label>
                            <input
                              type="number"
                              name="DueDateTminus"
                              value={newTask[service.ServiceID]?.DueDateTminus || ''}
                              onChange={(e) => handleInputChange(e, service.ServiceID)}
                              required
                            />
                          </div>
                          <button type="submit">Create Task</button>
                        </form>
                      </td>
                    </tr>
                  )}
                  {tasks[service.ServiceID] && tasks[service.ServiceID].map((task) => (
                    <tr key={task.TaskID} className="task-row">
                      <td>{task.TaskName}</td>
                      <td>
                        <label>Start Date T-:</label>
                        <input
                          type="text"
                          name="StartDateTminus"
                          value={editedTask[task.TaskID]?.StartDateTminus || task.StartDateTminus}
                          onChange={(e) => handleInputChange(e, service.ServiceID, task.TaskID)}
                        />
                      </td>
                      <td>
                        <label>Due Date T-:</label>
                        <input
                          type="text"
                          name="DueDateTminus"
                          value={editedTask[task.TaskID]?.DueDateTminus || task.DueDateTminus}
                          onChange={(e) => handleInputChange(e, service.ServiceID, task.TaskID)}
                        />
                      </td>
                      <td>
                        <button onClick={() => handleEditSubmit(task.TaskID, service.ServiceID)}>Save</button>
                      </td>
                      <td>
                        <button onClick={() => handleDelete(task.TaskID, service.ServiceID)}>Delete</button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default MasterList;
