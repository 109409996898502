import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Make sure to use your custom axios instance if needed
import styles from './Tasks.css';
import { useNavigate } from 'react-router-dom';

function Tasks() {
  const [allTasks, setAllTasks] = useState([]); // Keep all tasks in a separate state
  const [tasks, setTasks] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    const filtered = filterTasks(allTasks, assigneeFilter, statusFilter);
    const sorted = sortTasks(filtered, sortColumn, sortOrder);
    setTasks(sorted);
  }, [assigneeFilter, statusFilter, sortColumn, sortOrder, allTasks]);

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get('/api/all-event-tasks', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data) {
        throw new Error('No data received');
      }

      const filteredTasks = response.data.filter(task => task.Status !== 'NotUsed');
      setAllTasks(filteredTasks); // Update allTasks state with fetched tasks

      const uniqueAssignees = Array.from(new Set(filteredTasks.map(task => task.Assignee)));
      setAssignees(uniqueAssignees);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
      navigate('/logout'); // Redirect to logout if authentication fails
    }
  };

  const filterTasks = (tasks, assignee, status) => {
    let filtered = tasks;

    if (assignee) {
      filtered = filtered.filter(task => task.Assignee === assignee);
    }

    if (status) {
      filtered = filtered.filter(task => task.Status === status);
    }

    return filtered;
  };

  const sortTasks = (tasks, column, order) => {
    if (!column) return tasks;

    return tasks.sort((a, b) => {
      let aValue = a[column];
      let bValue = b[column];

      if (column === 'DueDate') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (column) => {
    const order = (sortColumn === column && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(order);
  };

  const sortIcon = (column) => {
    if (sortColumn === column) {
      return sortOrder === 'asc' ? '↑' : '↓';
    }
    return '↕';
  };

  const handleAssigneeFilterChange = (e) => {
    setAssigneeFilter(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const updateTaskStatus = async (id, updatedStatus) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`/api/all-event-tasks/${id}`, { Status: updatedStatus }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Updated task status response:', response.data);
      setAllTasks(allTasks.map(t => t.id === id ? { ...t, Status: updatedStatus } : t));
    } catch (error) {
      console.error('Failed to update task status:', error.response.data);
      alert('Failed to update task status. Please try again.');
    }
  };

  const updateTaskAssignee = async (id, updatedAssignee) => {
    try {
      const token = localStorage.getItem('token');
      const task = allTasks.find(t => t.id === id);
      const updatedTaskData = { Assignee: updatedAssignee, Status: task.Status, DueDate: task.DueDate }; // Include status and due date
      console.log('Updating assignee with:', updatedTaskData);
      const response = await axios.put(`/api/all-event-tasks/${id}`, updatedTaskData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Updated task assignee response:', response.data);
      setAllTasks(allTasks.map(t => t.id === id ? { ...t, Assignee: updatedAssignee } : t));
    } catch (error) {
      console.error('Failed to update task assignee:', error.response.data);
      alert('Failed to update task assignee. Please try again.');
    }
  };

  const updateTaskDueDate = async (id, updatedDueDate) => {
    try {
      const token = localStorage.getItem('token');
      const task = allTasks.find(t => t.id === id);
      const formattedDueDate = new Date(updatedDueDate).toISOString(); // Ensure the date is formatted correctly
      const updatedTaskData = { DueDate: formattedDueDate, Status: task.Status, Assignee: task.Assignee }; // Include status and assignee
      console.log('Updating due date with:', updatedTaskData);
      const response = await axios.put(`/api/all-event-tasks/${id}`, updatedTaskData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Updated task due date response:', response.data);
      setAllTasks(allTasks.map(t => t.id === id ? { ...t, DueDate: formattedDueDate } : t));
    } catch (error) {
      console.error('Failed to update task due date:', error.response.data);
      alert('Failed to update task due date. Please try again.');
    }
  };

  return (
    <div className={styles.tasksContainer}>
      <h1>Tasks</h1>
      <div className={styles.filters}>
        <div>
          <label>Assignee:</label>
          <select
            value={assigneeFilter}
            onChange={handleAssigneeFilterChange}
          >
            <option value="">All</option>
            {assignees.map(assignee => (
              <option key={assignee} value={assignee}>{assignee}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Status:</label>
          <select
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">All</option>
            <option value="NotStarted">Not Started</option>
            <option value="InProgress">In Progress</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
      </div>
      <table className={styles.tasksTable}>
        <thead>
          <tr>
            <th onClick={() => handleSort('TaskName')}>Task Name {sortIcon('TaskName')}</th>
            <th onClick={() => handleSort('EventName')}>Event Name {sortIcon('EventName')}</th>
            <th onClick={() => handleSort('Assignee')}>Assignee {sortIcon('Assignee')}</th>
            <th onClick={() => handleSort('DueDate')}>Due Date {sortIcon('DueDate')}</th>
            <th onClick={() => handleSort('Status')}>Status {sortIcon('Status')}</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td>{task.TaskName}</td>
              <td>{task.EventName}</td>
              <td>
                <input
                  type="text"
                  value={task.Assignee}
                  onChange={(e) => updateTaskAssignee(task.id, e.target.value)}
                  style={{ width: '150px' }} // Adjust the width to fit typical name lengths
                />
              </td>
              <td>
                <input
                  type="date"
                  value={new Date(task.DueDate).toISOString().split('T')[0]}
                  onChange={(e) => updateTaskDueDate(task.id, e.target.value)}
                />
              </td>
              <td>
                <select
                  value={task.Status}
                  onChange={(e) => updateTaskStatus(task.id, e.target.value)}
                >
                  <option value="NotStarted">Not Started</option>
                  <option value="InProgress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Tasks;
