import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Calendar from './components/Calendar';
import Events from './components/Events';
import Progress from './components/Progress';
import ServiceSelection from './components/ServiceSelection';
import ServiceTemplatePage from './components/ServiceTemplatePage';
import TaskTemplatePage from './components/TaskTemplatePage';
import MasterList from './components/MasterList';
import EventDetails from './components/EventDetails';
import Reports from './components/Reports'; // Updated name
import TemplateLandingPage from './components/TemplateLandingPage';
import Login from './components/Login';
import Register from './components/Register';
import Tasks from './components/Tasks';
import ProtectedRoute from './components/ProtectedRoute';
import axios from 'axios';
import logo from './assets/logo.png'; // Import the logo

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userInfo = JSON.parse(localStorage.getItem('user'));
    if (token && userInfo) {
      // Validate token with server
      axios.post('/api/auth/validate-token', { token })
        .then(response => {
          if (response.data.valid) {
            setAuth(true);
            setUser(userInfo); // Set user info from localStorage
          } else {
            setAuth(false);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
          }
          setLoading(false);
        })
        .catch(() => {
          setAuth(false);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          setLoading(false);
        });
    } else {
      setAuth(false);
      setLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleClickOutside = (event) => {
    if (!event.target.closest('.account-button') && !event.target.closest('.dropdown-menu')) {
      closeDropdown();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuth(false);
    navigate('/login');
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while validating the token
  }

  const getUserInitials = () => {
    if (user) {
      return user.firstName.charAt(0) + user.lastName.charAt(0);
    }
    return '';
  };

  return (
    <div className="App">
      <header className="header">
        <button className="hamburger" onClick={toggleDrawer}>☰</button>
        <Link to="/" className="logo">
          <img src={logo} alt="Tempo Workspace Logo" />
        </Link>
        {auth && location.pathname !== '/login' && (
          <>
            <div className="nav-container">
              <div className="nav-items">
                <Link to="/dashboard">Reports</Link>
                <Link to="/events">Events</Link>
                <Link to="/tasks">Tasks</Link>
                <Link to="/progress">Progress</Link>
              </div>
              <div className="account-button" onClick={toggleDropdown}>
                {getUserInitials()}
                <div className={`dropdown-menu ${dropdownOpen ? 'open' : ''}`}>
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          </>
        )}
        <nav className={`drawer ${isOpen ? 'open' : ''}`}>
          <button className="close-button" onClick={toggleDrawer}>X</button>
          <ul>
            {auth && (
              <>
                <li><Link to="/dashboard" onClick={toggleDrawer}>Reports</Link></li>
                <li><Link to="/events" onClick={toggleDrawer}>Events</Link></li>
                <li><Link to="/tasks" onClick={toggleDrawer}>Tasks</Link></li>
                <li><Link to="/progress" onClick={toggleDrawer}>Progress</Link></li>
                <li><button onClick={handleLogout}>Logout</button></li>
              </>
            )}
          </ul>
        </nav>
      </header>
      <main className="main">
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute auth={auth}>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calendar"
            element={
              <ProtectedRoute auth={auth}>
                <Calendar />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute auth={auth}>
                <Events />
              </ProtectedRoute>
            }
          />
          <Route
            path="/progress"
            element={
              <ProtectedRoute auth={auth}>
                <Progress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/:eventId"
            element={
              <ProtectedRoute auth={auth}>
                <ServiceSelection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services-template"
            element={
              <ProtectedRoute auth={auth}>
                <ServiceTemplatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tasks-template"
            element={
              <ProtectedRoute auth={auth}>
                <TaskTemplatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/master-list"
            element={
              <ProtectedRoute auth={auth}>
                <MasterList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/event-details/:eventId"
            element={
              <ProtectedRoute auth={auth}>
                <EventDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/templates"
            element={
              <ProtectedRoute auth={auth}>
                <TemplateLandingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tasks"
            element={
              <ProtectedRoute auth={auth}>
                <Tasks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute auth={auth}>
                <div>
                  <h1>Welcome to Tempo Workspace</h1>
                  <p>Select a page from the menu to navigate.</p>
                </div>
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={<Navigate to={auth ? "/dashboard" : "/login"} />}
          />
        </Routes>
      </main>
      {dropdownOpen && <div className="backdrop" onClick={closeDropdown}></div>}
    </div>
  );
}

export default App;
