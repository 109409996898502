import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance'; // Use the custom axios instance
import { useNavigate } from 'react-router-dom';
import './ServiceTemplatePage.css'; // Import the CSS file

const ServiceTemplatePage = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [services, setServices] = useState([]);
  const [groupedServices, setGroupedServices] = useState({});
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [showAddServiceForm, setShowAddServiceForm] = useState(false);
  const [newService, setNewService] = useState({ ServiceName: '', ServiceCategory: '' });
  const navigate = useNavigate();

  useEffect(() => {
    fetchTemplateNames();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      fetchServices(selectedTemplate);
    }
  }, [selectedTemplate]);

  const fetchTemplateNames = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/services-template/names', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTemplates(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Failed to fetch template names:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const fetchServices = async (templateName) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/services-template?ServiceTemplateName=${templateName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const servicesArray = Array.isArray(response.data) ? response.data : [];
      setServices(servicesArray);
      groupServicesByCategory(servicesArray);
    } catch (error) {
      console.error('Failed to fetch services for template:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const groupServicesByCategory = (servicesArray) => {
    const grouped = servicesArray.reduce((acc, service) => {
      const category = service.ServiceCategory;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(service);
      return acc;
    }, {});
    setGroupedServices(grouped);
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleCheckboxChange = async (e, serviceId, field) => {
    const updatedServices = services.map(service => {
      if (service.ServiceID === serviceId) {
        let updatedService = { ...service, [field]: e.target.checked };

        if (field === 'TemplateSelect' && !e.target.checked) {
          updatedService.DefaultSelect = false;
        } else if (field === 'DefaultSelect' && e.target.checked) {
          updatedService.TemplateSelect = true;
        }

        return updatedService;
      }
      return service;
    });
    setServices(updatedServices);
    groupServicesByCategory(updatedServices);

    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/services-template/${serviceId}`, {
        [field]: e.target.checked,
        ServiceTemplateName: selectedTemplate,
        TemplateSelect: updatedServices.find(service => service.ServiceID === serviceId).TemplateSelect,
        DefaultSelect: updatedServices.find(service => service.ServiceID === serviceId).DefaultSelect,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Failed to update service:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleCreateTemplate = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/api/services-template/create', {
        ServiceTemplateName: newTemplateName,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Template created successfully:', response.data);
      setShowCreateTemplate(false);
      setNewTemplateName('');
      fetchTemplateNames();
    } catch (error) {
      console.error('Failed to create template:', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleAddServiceInputChange = (e) => {
    const { name, value } = e.target;
    setNewService({ ...newService, [name]: value });
  };

  const handleAddServiceSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Adding service:', newService);
      const token = localStorage.getItem('token');
      const response = await axios.post('/api/all-services', newService, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Service added successfully:', response.data);
      alert('Service added successfully!');
      setShowAddServiceForm(false);
      setNewService({ ServiceName: '', ServiceCategory: '' });
      fetchServices(selectedTemplate);
    } catch (error) {
      console.error('Failed to add service:', error);
      alert('Failed to add service');
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  return (
    <div>
      <h1>Service Templates</h1>
      <div className="page-header">
        <div>
          <label>Select Template:</label>
          <select value={selectedTemplate} onChange={handleTemplateChange}>
            <option value="">Select Template</option>
            {templates.map((template, index) => (
              <option key={index} value={template}>{template}</option>
            ))}
          </select>
          <button onClick={() => setShowCreateTemplate(!showCreateTemplate)}>+</button>
        </div>
        <button className="add-service-button" onClick={() => setShowAddServiceForm(!showAddServiceForm)}>
          Add Service
        </button>
      </div>
      {showCreateTemplate && (
        <div style={{ marginTop: '10px' }}>
          <input
            type="text"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
            placeholder="New Template Name"
          />
          <button onClick={handleCreateTemplate}>Create</button>
        </div>
      )}
      {showAddServiceForm && (
        <form onSubmit={handleAddServiceSubmit} style={{ marginTop: '10px' }}>
          <div>
            <label>Service Name:</label>
            <input
              type="text"
              name="ServiceName"
              value={newService.ServiceName}
              onChange={handleAddServiceInputChange}
              required
            />
          </div>
          <div>
            <label>Service Category:</label>
            <select
              name="ServiceCategory"
              value={newService.ServiceCategory}
              onChange={handleAddServiceInputChange}
              required
            >
              <option value="">Select Category</option>
              <option value="Timing">Timing</option>
              <option value="Results/Awards">Results/Awards</option>
              <option value="Web Services">Web Services</option>
              <option value="Equipment">Equipment</option>
              <option value="Bib/Shirt/Medal/Swag">Bib/Shirt/Medal/Swag</option>
              <option value="Event Management">Event Management</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <button type="submit">Add Service</button>
        </form>
      )}
      {selectedTemplate && (
        Object.entries(groupedServices).map(([category, services]) => (
          <div key={category}>
            <h2>{category}</h2>
            <table>
              <thead>
                <tr>
                  <th>Service ID</th>
                  <th>Service Name</th>
                  <th>Template Select</th>
                  <th>Default Select</th>
                </tr>
              </thead>
              <tbody>
                {services.map(service => (
                  <tr key={service.ServiceID}>
                    <td>{service.ServiceID}</td>
                    <td>{service.ServiceName}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={service.TemplateSelect}
                        onChange={(e) => handleCheckboxChange(e, service.ServiceID, 'TemplateSelect')}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={service.DefaultSelect}
                        onChange={(e) => handleCheckboxChange(e, service.ServiceID, 'DefaultSelect')}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))
      )}
    </div>
  );
};

export default ServiceTemplatePage;
