import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance'; // Use the custom axios instance
import { useParams, useNavigate } from 'react-router-dom';
import './ServiceSelection.css';

function ServiceSelection() {
  const { eventId } = useParams();
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (eventId) {
      fetchServices();
    }
  }, [eventId]);

  const fetchServices = async () => {
    try {
      console.log(`Fetching services for event ID: ${eventId}`);
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/events/${eventId}/services-selected`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const filteredServices = response.data.filter(service => service.TemplateSelect);
      setServices(filteredServices);
      const selected = filteredServices.filter(service => service.ServiceSelected).map(service => service.ServiceID);
      setSelectedServices(selected);
    } catch (error) {
      console.error('Failed to fetch services', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleCheckboxChange = async (serviceId) => {
    const isSelected = selectedServices.includes(serviceId);
    const newSelectedServices = isSelected
      ? selectedServices.filter(id => id !== serviceId)
      : [...selectedServices, serviceId];

    setSelectedServices(newSelectedServices);

    try {
      const token = localStorage.getItem('token');
      await axios.post(`/api/events/${eventId}/services`, { services: newSelectedServices }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Failed to save services', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const groupServicesByCategory = (services) => {
    return services.reduce((groups, service) => {
      const category = service.ServiceCategory;
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(service);
      return groups;
    }, {});
  };

  const groupedServices = groupServicesByCategory(services);

  return (
    <div className="container">
      <h1>Select Services for Event {eventId}</h1>
      {Object.keys(groupedServices).map(category => (
        <div key={category}>
          <h2>{category}</h2>
          <div className="services-grid">
            {groupedServices[category].map((service) => (
              <div key={service.ServiceID} className="service-item">
                <input
                  type="checkbox"
                  checked={selectedServices.includes(service.ServiceID)}
                  onChange={() => handleCheckboxChange(service.ServiceID)}
                />
                <span className="service-name">{service.ServiceName}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServiceSelection;
