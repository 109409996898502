import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NotesTemplatePage.css';
import EditableNote from './EditableNote';
import { useNavigate } from 'react-router-dom';

const NotesTemplatePage = () => {
  const [notesTemplates, setNotesTemplates] = useState([]);
  const [newNoteTemplate, setNewNoteTemplate] = useState({
    NoteCategory: '',
    NoteName: '',
    Note1: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotesTemplates();
  }, []);

  const fetchNotesTemplates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/notes-templates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotesTemplates(response.data);
    } catch (error) {
      console.error('Failed to fetch notes templates', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewNoteTemplate({
      ...newNoteTemplate,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/notes-template', newNoteTemplate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNewNoteTemplate({
        NoteCategory: '',
        NoteName: '',
        Note1: '',
      });
      fetchNotesTemplates();
    } catch (error) {
      console.error('Failed to create notes template', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleUpdate = async (updatedNote) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/notes-template/${updatedNote.NoteTemplateID}`, updatedNote, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchNotesTemplates();
    } catch (error) {
      console.error('Failed to update notes template', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const groupedNotesTemplates = notesTemplates.reduce((acc, note) => {
    if (!acc[note.NoteCategory]) {
      acc[note.NoteCategory] = [];
    }
    acc[note.NoteCategory].push(note);
    return acc;
  }, {});

  const sortedCategories = Object.keys(groupedNotesTemplates).sort();

  return (
    <div className="notes-template-page">
      <h1>Notes Template</h1>
      <form onSubmit={handleSubmit} className="notes-template-form">
        <div className="form-group">
          <label>Note Category:</label>
          <select name="NoteCategory" value={newNoteTemplate.NoteCategory} onChange={handleInputChange} required>
            <option value="">Select Category</option>
            <option value="Race Director Tasks">Race Director Tasks</option>
            <option value="Event Details">Event Details</option>
            <option value="Main Note">Main Note</option>
            <option value="Packet Pickup">Packet Pickup</option>
            <option value="Registration Setup">Registration Setup</option>
            <option value="Race Events">Race Events</option>
            <option value="Travel">Travel</option>
            <option value="Course">Course</option>
            <option value="Timeline">Timeline</option>
            <option value="Registration/Bibs/Tags">Registration/Bibs/Tags</option>
            <option value="Policies and Procedures">Policies and Procedures</option>
            <option value="Timing Plan">Timing Plan</option>
            <option value="Scoring">Scoring</option>
            <option value="Locations">Locations</option>
            <option value="Post Mortem">Post Mortem</option>
          </select>
        </div>
        <div className="form-group">
          <label>Note Name:</label>
          <input type="text" name="NoteName" value={newNoteTemplate.NoteName} onChange={handleInputChange} required />
        </div>
        <div className="form-group">
          <label>Note 1:</label>
          <input type="text" name="Note1" value={newNoteTemplate.Note1} onChange={handleInputChange} />
        </div>
        <button type="submit">Add Note Template</button>
      </form>

      <h2>Existing Notes Templates</h2>
      {sortedCategories.map((category) => (
        <div key={category} className="notes-category">
          <h3>{category}</h3>
          <table className="notes-template-table">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Note Name</th>
                <th>Note 1</th>
                <th>Note 2</th>
                <th>Text 1</th>
                <th>Text 2</th>
                <th>Boolean</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Due Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groupedNotesTemplates[category]
                .sort((a, b) => a.Rank - b.Rank)
                .map((note) => (
                  <EditableNote key={note.NoteTemplateID} note={note} handleUpdate={handleUpdate} />
                ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default NotesTemplatePage;
