import React, { useEffect, useState } from 'react';
import axios from '../axiosInstance'; // Use the custom axios instance
import { useParams, useNavigate } from 'react-router-dom';
import './EventDetails.css';

function EventDetails() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [notes, setNotes] = useState([]);
  const [services, setServices] = useState([]);
  const [groupedServices, setGroupedServices] = useState({});
  const [unusedServices, setUnusedServices] = useState([]);
  const [showUnusedServices, setShowUnusedServices] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [unusedTasks, setUnusedTasks] = useState([]);
  const [showUnusedTasks, setShowUnusedTasks] = useState(false);

  useEffect(() => {
    fetchEventDetails();
    fetchNotes();
    fetchServices();
    fetchTasks();
  }, [eventId]);

  useEffect(() => {
    if (showUnusedServices) {
      fetchUnusedServices();
    }
  }, [showUnusedServices]);

  useEffect(() => {
    if (showUnusedTasks) {
      fetchUnusedTasks();
    }
  }, [showUnusedTasks]);

  useEffect(() => {
    console.log('Services state updated:', services);
  }, [services]);

  const fetchEventDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const eventResponse = await axios.get(`/api/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvent(eventResponse.data);
      console.log('Fetched event details:', eventResponse.data);
    } catch (error) {
      console.error('Failed to fetch event details', error);
    }
  };

  const fetchNotes = async () => {
    try {
      const token = localStorage.getItem('token');
      const notesResponse = await axios.get(`/api/event-notes/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotes(Array.isArray(notesResponse.data) ? notesResponse.data : []);
      console.log('Fetched notes:', notesResponse.data);
    } catch (error) {
      console.error('Failed to fetch notes', error);
      setNotes([]); // Ensure notes is always an array
    }
  };

  const fetchServices = async () => {
    try {
      const token = localStorage.getItem('token');
      const servicesResponse = await axios.get(`/api/events/${eventId}/services-selected`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setServices(servicesResponse.data);
      groupServicesByCategory(servicesResponse.data);
      console.log('Fetched services:', servicesResponse.data);
    } catch (error) {
      console.error('Failed to fetch services', error);
    }
  };

  const fetchUnusedServices = async () => {
    try {
      const token = localStorage.getItem('token');
      const unusedServicesResponse = await axios.get(`/api/events/${eventId}/services-selected`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { showAll: true },
      });
      const unused = unusedServicesResponse.data.filter(service =>
        !service.TemplateSelect && !service.ServiceSelected
      );
      setUnusedServices(unused);
      console.log('Fetched unused services:', unused);
    } catch (error) {
      console.error('Failed to fetch unused services', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      const tasksResponse = await axios.get(`/api/events/${eventId}/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTasks(tasksResponse.data);
      console.log('Fetched tasks:', tasksResponse.data);
    } catch (error) {
      console.error('Failed to fetch tasks', error);
    }
  };

  const fetchUnusedTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      const unusedTasksResponse = await axios.get(`/api/events/${eventId}/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { showAll: true },
      });
      const unused = unusedTasksResponse.data.filter(task => task.Status === 'NotUsed');
      setUnusedTasks(unused);
      console.log('Fetched unused tasks:', unused);
    } catch (error) {
      console.error('Failed to fetch unused tasks', error);
    }
  };

  const handleServiceToggle = async (serviceId, currentSelection) => {
    console.log('Toggling service:', serviceId, 'Current selection:', currentSelection);

    // Optimistically update the UI
    setServices(prevServices => {
      const newServices = prevServices.map(service =>
        service.ServiceID === serviceId ? { ...service, ServiceSelected: !currentSelection } : service
      );
      console.log('Updated services state:', newServices);
      return newServices;
    });

    try {
      const token = localStorage.getItem('token');
      // Update the database
      await axios.post(`/api/events/${eventId}/services/toggle`, {
        serviceId,
        serviceSelected: !currentSelection
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Re-fetch services to ensure UI reflects the latest state
      await fetchServices();
      // Fetch updated tasks after the service update
      await fetchTasks();
      await fetchUnusedTasks();
    } catch (error) {
      console.error('Failed to update services', error);
      // Revert the optimistic update if the request fails
      setServices(prevServices =>
        prevServices.map(service =>
          service.ServiceID === serviceId ? { ...service, ServiceSelected: currentSelection } : service
        )
      );
    }
  };

  const handleNoteChange = (noteId, field, value) => {
    setNotes(notes.map(note =>
      note.EventNoteID === noteId ? { ...note, [field]: value } : note
    ));
  };

  const saveNote = async (noteId, updatedFields) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/event-notes/${noteId}`, updatedFields, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Failed to save note', error);
    }
  };

  const handleTextNoteChange = (noteId, newText) => {
    handleNoteChange(noteId, 'Text1', newText);
  };

  const handleBooleanNoteChange = async (noteId, newBoolean) => {
    handleNoteChange(noteId, 'Boolean', newBoolean);
    try {
      await saveNote(noteId, { Boolean: newBoolean });
    } catch (error) {
      console.error('Failed to save note', error);
    }
  };

  const groupServicesByCategory = (services) => {
    const grouped = services.reduce((acc, service) => {
      const category = service.ServiceCategory || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(service);
      return acc;
    }, {});
    setGroupedServices(grouped);
    console.log('Grouped services by category:', grouped);
  };

  if (!event) return <div>Loading...</div>;

  const groupedNotes = notes.reduce((acc, note) => {
    if (!acc[note.NoteCategory]) {
      acc[note.NoteCategory] = [];
    }
    acc[note.NoteCategory].push(note);
    return acc;
  }, {});

  // Ensure Main Note is at the top
  const sortedCategories = Object.keys(groupedNotes).sort((a, b) => {
    if (a === 'Main Note') return -1;
    if (b === 'Main Note') return 1;
    return a.localeCompare(b);
  });

  return (
    <div className="event-details-container">
      <a className="back-link" onClick={() => navigate('/events')}>Back to All Events</a>
      <h1>{event.EventName}</h1>
      <p>Date: {new Date(event.EventDate).toLocaleDateString()}</p>
      <p>Description: {event.Description}</p>

      <div className="section">
        <h2>Notes</h2>
        {sortedCategories.map(category => (
          <div key={category} className="note-category">
            <h3>{category}</h3>
            <table className="notes-table">
              <tbody>
                {groupedNotes[category].sort((a, b) => a.Rank - b.Rank).map(note => (
                  <tr key={`${category}-${note.EventNoteID}`}>
                    {category === 'Main Note' ? (
                      <td>
                        <textarea
                          className="main-note-textarea"
                          value={note.Text1}
                          onChange={(e) => handleTextNoteChange(note.EventNoteID, e.target.value)}
                          onBlur={() => saveNote(note.EventNoteID, { Text1: note.Text1 })}
                          rows="10"
                        />
                      </td>
                    ) : category === 'Policies and Procedures' || category === 'Scoring' ? (
                      <>
                        <td style={{ width: '35ch' }}>{note.NoteName}</td>
                        <td>
                          <input
                            type="text"
                            value={note.Note1}
                            onChange={(e) => handleNoteChange(note.EventNoteID, 'Note1', e.target.value)}
                            onBlur={() => saveNote(note.EventNoteID, { Note1: note.Note1 })}
                            style={{ height: '1em' }}
                          />
                        </td>
                      </>
                    ) : category === 'Course' ? (
                      <>
                        <td style={{ width: '5ch' }}>
                          <input
                            type="checkbox"
                            checked={note.Boolean}
                            onChange={(e) => handleBooleanNoteChange(note.EventNoteID, e.target.checked)}
                          />
                        </td>
                        <td style={{ width: '35ch' }}>{note.NoteName}</td>
                        <td>{note.Note1}</td>
                      </>
                    ) : category === 'Post Mortem' ? (
                      <td>
                        <textarea
                          className="main-note-textarea"
                          value={note.Text1}
                          onChange={(e) => handleTextNoteChange(note.EventNoteID, e.target.value)}
                          onBlur={() => saveNote(note.EventNoteID, { Text1: note.Text1 })}
                          rows="10"
                        />
                      </td>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>

      <div className="section">
        <h2>Services</h2>
        {Object.keys(groupedServices).map(category => (
          <div key={category} className="service-category">
            <h3>{category}</h3>
            <ul className="service-list">
              {groupedServices[category].map(service => (
                <li key={service.ServiceID} className="service-item">
                  <input
                    type="checkbox"
                    checked={service.ServiceSelected}
                    onChange={() => handleServiceToggle(service.ServiceID, service.ServiceSelected)}
                  />
                  {service.ServiceName}
                </li>
              ))}
            </ul>
          </div>
        ))}

        <button onClick={() => setShowUnusedServices(!showUnusedServices)}>
          {showUnusedServices ? 'Hide Unused Services' : 'Show Unused Services'}
        </button>

        {showUnusedServices && (
          <div>
            <h3>Unused Services</h3>
            <ul className="service-list">
              {unusedServices.map(service => (
                <li key={service.ServiceID}>
                  <input
                    type="checkbox"
                    checked={service.ServiceSelected}
                    onChange={() => handleServiceToggle(service.ServiceID, service.ServiceSelected)}
                  />
                  {service.ServiceName}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="section">
        <h2>Tasks</h2>
        <table>
          <thead>
            <tr>
              <th>Task Name</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>Due Date</th>
              <th>Assignee</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(task => (
              <tr key={task.TaskID}>
                <td>{task.TaskName}</td>
                <td>{task.Status}</td>
                <td>{new Date(task.StartDate).toLocaleDateString()}</td>
                <td>{new Date(task.DueDate).toLocaleDateString()}</td>
                <td>{task.Assignee}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={() => setShowUnusedTasks(!showUnusedTasks)}>
          {showUnusedTasks ? 'Hide Other Tasks' : 'Show Other Tasks'}
        </button>

        {showUnusedTasks && (
          <div>
            <h3>Other Tasks</h3>
            <table>
              <thead>
                <tr>
                  <th>Task Name</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>Due Date</th>
                  <th>Assignee</th>
                </tr>
              </thead>
              <tbody>
                {unusedTasks.map(task => (
                  <tr key={task.TaskID}>
                    <td>{task.TaskName}</td>
                    <td>{task.Status}</td>
                    <td>{new Date(task.StartDate).toLocaleDateString()}</td>
                    <td>{new Date(task.DueDate).toLocaleDateString()}</td>
                    <td>{task.Assignee}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventDetails;
