// src/components/Calendar.js
import React, { useEffect, useState } from 'react';
import axios from '../axiosInstance'; // Use the custom axios instance
import './Calendar.css';

function Calendar() {
  const [calendarData, setCalendarData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCalendarData();
  }, []);

  const fetchCalendarData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get('/api/calendar', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setCalendarData(response.data);
      setError(''); // Clear any previous errors
    } catch (error) {
      console.error('Failed to fetch calendar data:', error);
      setError('Failed to fetch calendar data. Please try again.');
    }
  };

  return (
    <div className="calendar">
      <h1>Calendar</h1>
      {error && <p className="error-message">{error}</p>}
      <ul>
        {calendarData.map((item, index) => (
          <li key={index}>{item.title}</li> // Adjust to match your data structure
        ))}
      </ul>
    </div>
  );
}

export default Calendar;
