import React, { useState } from 'react';
import axios from '../axiosInstance'; // Use the custom axios instance

const EditableNote = ({ note, handleUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedNote, setEditedNote] = useState({ ...note });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedNote({
      ...editedNote,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/event-notes/${editedNote.EventNoteID}`, editedNote, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleUpdate(editedNote);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save note', error);
    }
  };

  return (
    <tr>
      {isEditing ? (
        <>
          <td><input type="number" name="Rank" value={editedNote.Rank} onChange={handleInputChange} /></td>
          <td><input type="text" name="NoteName" value={editedNote.NoteName} onChange={handleInputChange} /></td>
          <td><input type="text" name="Note1" value={editedNote.Note1} onChange={handleInputChange} /></td>
          <td><input type="text" name="Note2" value={editedNote.Note2} onChange={handleInputChange} /></td>
          <td><textarea name="Text1" value={editedNote.Text1} onChange={handleInputChange}></textarea></td>
          <td><textarea name="Text2" value={editedNote.Text2} onChange={handleInputChange}></textarea></td>
          <td><input type="checkbox" name="Boolean" checked={editedNote.Boolean} onChange={handleInputChange} /></td>
          <td><input type="date" name="StartDate" value={editedNote.StartDate ? editedNote.StartDate.split('T')[0] : ''} onChange={handleInputChange} /></td>
          <td><input type="date" name="EndDate" value={editedNote.EndDate ? editedNote.EndDate.split('T')[0] : ''} onChange={handleInputChange} /></td>
          <td><input type="date" name="DueDate" value={editedNote.DueDate ? editedNote.DueDate.split('T')[0] : ''} onChange={handleInputChange} /></td>
          <td>
            <button onClick={handleSave}>Save</button>
          </td>
        </>
      ) : (
        <>
          <td>{note.Rank}</td>
          <td>{note.NoteName}</td>
          <td>{note.Note1}</td>
          <td>{note.Note2}</td>
          <td>{note.Text1}</td>
          <td>{note.Text2}</td>
          <td>{note.Boolean ? 'Yes' : 'No'}</td>
          <td>{note.StartDate ? new Date(note.StartDate).toLocaleDateString() : ''}</td>
          <td>{note.EndDate ? new Date(note.EndDate).toLocaleDateString() : ''}</td>
          <td>{note.DueDate ? new Date(note.DueDate).toLocaleDateString() : ''}</td>
          <td>
            <button onClick={() => setIsEditing(true)}>Edit</button>
          </td>
        </>
      )}
    </tr>
  );
};

export default EditableNote;
