import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Progress.css';

function Progress() {
  const [selectedCategory, setSelectedCategory] = useState('Overall Progress');
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/events', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvents(response.data);
    } catch (error) {
      console.error('Failed to fetch events', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const calculateProgress = (tasks) => {
    if (!tasks) {
      return 0;
    }
    const totalTasks = tasks.filter(task => task.Status !== 'NotUsed').length;
    const completedTasks = tasks.filter(task => task.Status === 'Complete').length;
    return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
  };

  const renderProgressBar = (progress) => (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        {Math.round(progress)}%
      </div>
    </div>
  );

  return (
    <div className="progress-container">
      <h1>Progress</h1>
      <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
        <option value="Overall Progress">Overall Progress</option>
        <option value="Labor">Labor</option>
        <option value="Bibs">Bibs</option>
      </select>
      {selectedCategory === 'Overall Progress' && (
        <table>
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Event Date</th>
              <th>Progress</th>
            </tr>
          </thead>
          <tbody>
            {events.map(event => {
              const progress = calculateProgress(event.tasks);
              return (
                <tr key={event.EventID}>
                  <td>{event.EventName}</td>
                  <td>{new Date(event.EventDate).toLocaleDateString()}</td>
                  <td>{renderProgressBar(progress)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {selectedCategory === 'Labor' && (
        <div>
          {/* Labor progress content goes here */}
        </div>
      )}
      {selectedCategory === 'Bibs' && (
        <div>
          {/* Bibs progress content goes here */}
        </div>
      )}
    </div>
  );
}

export default Progress;
