// src/components/Events.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../axiosInstance'; // Use the custom axios instance
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './Events.css'; // Import the CSS file

function Events() {
  const [events, setEvents] = useState([]);
  const [serviceTemplateNames, setServiceTemplateNames] = useState([]);
  const [taskTemplateNames, setTaskTemplateNames] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [newEvent, setNewEvent] = useState({
    EventName: '',
    EventType: '',
    EventDate: '',
    EventStartTime: '',
    Location: '',
    EstimatedParticipants: '',
    Status: '',
    ProjectManager: '',
    ServicesTemplate: '',
    TaskTemplate: ''
  });

  useEffect(() => {
    fetchEvents();
    fetchServiceTemplateNames();
    fetchTaskTemplateNames();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/events', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedEvents = response.data.sort((a, b) => new Date(a.EventDate) - new Date(b.EventDate));
      setEvents(sortedEvents);
    } catch (error) {
      console.error('Failed to fetch events', error);
    }
  };

  const fetchServiceTemplateNames = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/services-template/names', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setServiceTemplateNames(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Failed to fetch service template names', error);
    }
  };

  const fetchTaskTemplateNames = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/task-assignee-template/names', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTaskTemplateNames(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Failed to fetch task template names', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({
      ...newEvent,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/api/events', {
        ...newEvent,
        ServiceTemplateName: newEvent.ServicesTemplate // Ensure ServiceTemplateName is set correctly
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const newEventData = response.data;

      // Fetch tasks from TaskAssigneeTemplate and copy to AllEventTasks
      const taskAssigneeTemplatesResponse = await axios.get(`/api/task-assignee-template/${newEvent.TaskTemplate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const tasksToCopy = taskAssigneeTemplatesResponse.data.map(task => {
        const eventDate = new Date(newEvent.EventDate);
        const startDate = new Date(eventDate);
        const dueDate = new Date(eventDate);
        startDate.setDate(eventDate.getDate() - task.StartDateTminus);
        dueDate.setDate(eventDate.getDate() - task.DueDateTminus);

        return {
          ...task,
          EventID: newEventData.EventID,
          EventName: newEventData.EventName,
          StartDate: startDate,
          DueDate: dueDate,
          ServiceTemplateName: newEventData.ServicesTemplate // Ensure ServiceTemplateName is set
        };
      });

      await axios.post('/api/all-event-tasks/bulk-create', tasksToCopy, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setShowForm(false);
      setNewEvent({
        EventName: '',
        EventType: '',
        EventDate: '',
        EventStartTime: '',
        Location: '',
        EstimatedParticipants: '',
        Status: '',
        ProjectManager: '',
        ServicesTemplate: '',
        TaskTemplate: ''
      });
      fetchEvents();
    } catch (error) {
      console.error('Failed to add event', error);
    }
  };

  const groupEventsByDate = (events) => {
    const grouped = events.reduce((groups, event) => {
      const date = new Date(event.EventDate).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(event);
      return groups;
    }, {});

    // Convert grouped object to array and sort by date
    const sortedGroupedEvents = Object.keys(grouped).sort((a, b) => new Date(a) - new Date(b)).reduce((sortedGroups, date) => {
      sortedGroups[date] = grouped[date];
      return sortedGroups;
    }, {});

    return sortedGroupedEvents;
  };

  const groupedEvents = groupEventsByDate(events);

  return (
    <div className="events-container">
      <h1>Events</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <button onClick={() => setShowForm(!showForm)}>+ Add Event</button>
        <div>
          <Link to="/templates" className="templates-button">
            Templates <FontAwesomeIcon icon={faEdit} />
          </Link>
        </div>
      </div>
      {showForm && (
        <form onSubmit={handleSubmit} className="event-form">
          <div>
            <label>Event Name:</label>
            <input
              type="text"
              name="EventName"
              value={newEvent.EventName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Event Type:</label>
            <input
              type="text"
              name="EventType"
              value={newEvent.EventType}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Services Template:</label>
            <select
              name="ServicesTemplate"
              value={newEvent.ServicesTemplate}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Service Template</option>
              {Array.isArray(serviceTemplateNames) && serviceTemplateNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Task Template:</label>
            <select
              name="TaskTemplate"
              value={newEvent.TaskTemplate}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Task Template</option>
              {Array.isArray(taskTemplateNames) && taskTemplateNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Event Date:</label>
            <input
              type="date"
              name="EventDate"
              value={newEvent.EventDate}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Event Start Time:</label>
            <input
              type="time"
              name="EventStartTime"
              value={newEvent.EventStartTime}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Location:</label>
            <input
              type="text"
              name="Location"
              value={newEvent.Location}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Estimated Participants:</label>
            <input
              type="number"
              name="EstimatedParticipants"
              value={newEvent.EstimatedParticipants}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Status:</label>
            <input
              type="text"
              name="Status"
              value={newEvent.Status}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Project Manager:</label>
            <input
              type="text"
              name="ProjectManager"
              value={newEvent.ProjectManager}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
      {Object.keys(groupedEvents).map(date => (
        <div key={date} className="grouped-events">
          <h2>{date}</h2>
          <table className="event-table">
            <thead>
              <tr>
                <th>Event Name</th>
                <th>Event Type</th>
                <th>Event Date</th>
                <th>Event Start Time</th>
                <th>Location</th>
                <th>Estimated Participants</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {groupedEvents[date].map(event => (
                <tr key={event.EventID}>
                  <td><Link to={`/event-details/${event.EventID}`}>{event.EventName}</Link></td>
                  <td>{event.EventType}</td>
                  <td>{new Date(event.EventDate).toLocaleDateString()}</td>
                  <td>{event.EventStartTime}</td>
                  <td>{event.Location}</td>
                  <td>{event.EstimatedParticipants}</td>
                  <td>{event.Status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default Events;
