import React, { useEffect, useState } from 'react';
import axios from '../axiosInstance'; // Use the custom axios instance
import { useNavigate } from 'react-router-dom';
import './TaskTemplatePage.css'; // Ensure this file exists in the same directory

const TaskTemplatePage = () => {
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [defaultAssignee, setDefaultAssignee] = useState(''); // New state for Default Assignee
  const [showNewTemplateForm, setShowNewTemplateForm] = useState(false);
  const [tasks, setTasks] = useState({});
  const [newTask, setNewTask] = useState({});
  const [showTaskForm, setShowTaskForm] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchTaskTemplates();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      fetchTasks();
    }
  }, [selectedTemplate]);

  const fetchTaskTemplates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/task-assignee-template/names', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTaskTemplates(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Failed to fetch task templates:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const fetchTasks = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/task-assignee-template/${selectedTemplate}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const groupedTasks = response.data.reduce((acc, task) => {
        acc[task.ServiceCategory] = acc[task.ServiceCategory] || [];
        acc[task.ServiceCategory].push(task);
        return acc;
      }, {});
      setTasks(groupedTasks);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
    if (e.target.value === 'new') {
      setShowNewTemplateForm(true);
    } else {
      setShowNewTemplateForm(false);
    }
  };

  const handleNewTemplateNameChange = (e) => {
    setNewTemplateName(e.target.value);
  };

  const handleDefaultAssigneeChange = (e) => {
    setDefaultAssignee(e.target.value);
  };

  const handleNewTemplateSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/task-templates', {
        taskTemplate: newTemplateName,
        defaultAssignee: defaultAssignee // Pass the Default Assignee to the backend
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNewTemplateName('');
      setDefaultAssignee('');
      setShowNewTemplateForm(false);
      fetchTaskTemplates();
    } catch (error) {
      console.error('Failed to create new task template:', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleTaskChange = (e, taskId, field) => {
    setTasks(prevTasks => {
      const updatedTasks = { ...prevTasks };
      for (let category in updatedTasks) {
        updatedTasks[category] = updatedTasks[category].map(task => {
          if (task.TaskID === taskId) {
            return { ...task, [field]: e.target.value };
          }
          return task;
        });
      }
      return updatedTasks;
    });
  };

  const handleTaskBlur = async (taskId, field, value) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/task-assignee-template/${taskId}`, { [field]: value }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Failed to update task assignee:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleNewTaskChange = (e, serviceName) => {
    setNewTask(prevState => ({
      ...prevState,
      [serviceName]: {
        ...prevState[serviceName],
        [e.target.name]: e.target.value,
      }
    }));
  };

  const handleNewTaskSubmit = async (e, category) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/tasks', {
        ...newTask[category],
        serviceName: category,
        taskTemplate: selectedTemplate,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchTasks();
      setShowTaskForm(prevState => ({
        ...prevState,
        [category]: false,
      }));
    } catch (error) {
      console.error('Failed to create new task:', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const toggleTaskForm = (category) => {
    setShowTaskForm(prevState => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <div>
      <h1>Tasks Template</h1>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <label>Select Task Template:</label>
          <select value={selectedTemplate} onChange={handleTemplateChange}>
            <option value="">Select Template</option>
            {taskTemplates.map((template, index) => (
              <option key={index} value={template}>{template}</option>
            ))}
            <option value="new">Create New Template</option>
          </select>
          <button onClick={() => setShowNewTemplateForm(!showNewTemplateForm)}>+</button>
        </div>
        <button onClick={() => navigate('/master-list')}>Master List</button>
      </div>
      {showNewTemplateForm && (
        <form onSubmit={handleNewTemplateSubmit}>
          <div>
            <label>New Template Name:</label>
            <input
              type="text"
              value={newTemplateName}
              onChange={handleNewTemplateNameChange}
              required
            />
          </div>
          <div>
            <label>Default Assignee:</label>
            <input
              type="text"
              value={defaultAssignee}
              onChange={handleDefaultAssigneeChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
      {Object.keys(tasks).map(category => (
        <div key={category}>
          <h2>{category}</h2>
          <table>
            <thead>
              <tr>
                <th>Task Name</th>
                <th>StartDateTminus</th>
                <th>DueDateTminus</th>
                <th>Assignee</th>
              </tr>
            </thead>
            <tbody>
              {tasks[category]?.map(task => (
                <tr key={task.TaskID}>
                  <td>{task.TaskName}</td>
                  <td>{task.StartDateTminus}</td>
                  <td>{task.DueDateTminus}</td>
                  <td>
                    <input
                      type="text"
                      value={task.Assignee}
                      onChange={(e) => handleTaskChange(e, task.TaskID, 'Assignee')}
                      onBlur={(e) => handleTaskBlur(task.TaskID, 'Assignee', e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={() => toggleTaskForm(category)}>New {category} Task</button>
          {showTaskForm[category] && (
            <form onSubmit={(e) => handleNewTaskSubmit(e, category)}>
              <h3>Create New Task for {category}</h3>
              <div>
                <label>Task Name:</label>
                <input
                  type="text"
                  name="TaskName"
                  value={newTask[category]?.TaskName || ''}
                  onChange={(e) => handleNewTaskChange(e, category)}
                  required
                />
              </div>
              <div>
                <label>StartDateTminus:</label>
                <input
                  type="number"
                  name="StartDateTminus"
                  value={newTask[category]?.StartDateTminus || ''}
                  onChange={(e) => handleNewTaskChange(e, category)}
                />
              </div>
              <div>
                <label>DueDateTminus:</label>
                <input
                  type="number"
                  name="DueDateTminus"
                  value={newTask[category]?.DueDateTminus || ''}
                  onChange={(e) => handleNewTaskChange(e, category)}
                />
              </div>
              <div>
                <label>Assignee:</label>
                <input
                  type="text"
                  name="Assignee"
                  value={newTask[category]?.Assignee || ''}
                  onChange={(e) => handleNewTaskChange(e, category)}
                />
              </div>
              <button type="submit">Create Task</button>
            </form>
          )}
        </div>
      ))}
    </div>
  );
};

export default TaskTemplatePage;
