import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceTemplatePage from './ServiceTemplatePage';
import TaskTemplatePage from './TaskTemplatePage';
import NotesTemplatePage from './NotesTemplatePage';
import './TemplateLandingPage.css';

function TemplateLandingPage() {
  const [activeTemplate, setActiveTemplate] = useState('service');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="template-landing-page">
      <h1>Template Management</h1>
      <div className="template-tabs">
        <div
          className={`tab ${activeTemplate === 'service' ? 'active' : ''}`}
          onClick={() => setActiveTemplate('service')}
        >
          Service Templates
        </div>
        <div
          className={`tab ${activeTemplate === 'task' ? 'active' : ''}`}
          onClick={() => setActiveTemplate('task')}
        >
          Task Templates
        </div>
        <div
          className={`tab ${activeTemplate === 'note' ? 'active' : ''}`}
          onClick={() => setActiveTemplate('note')}
        >
          Note Templates
        </div>
      </div>
      <div className="template-content">
        {activeTemplate === 'service' && <ServiceTemplatePage />}
        {activeTemplate === 'task' && <TaskTemplatePage />}
        {activeTemplate === 'note' && <NotesTemplatePage />}
      </div>
    </div>
  );
}

export default TemplateLandingPage;
